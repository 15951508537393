.home-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(99, 97, 97, 0.7), rgba(0, 0, 0, 0.7)), 
              linear-gradient(to bottom, transparent 50%, rgb(0, 0, 0) 100%), 
              url('./assets/home-background.jpg') no-repeat center center/cover;
}

.background-image {
  display: none; /* Hide the image element as it's now part of the background */
}

.home-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.btncontainer {
  position: absolute;
  width: 200px;
  height: 50px;
  right: 58%;
  padding-top: 3%;
}

.google-btn {
  width: 184px;
  height: 42px;
  background-color: #596985;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: relative;
  margin-top: 8px;
  margin-left: 8px;
  width: 24px;
  height: 24px;
}
.google-btn .btn-text {
  float: right;
  margin: 10px 5px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.google-btn:hover {
  box-shadow: 0 0 6px #54647e;
}
.google-btn:active {
  background: #51617a;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

h2 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-width: 10rem;
}

.home-buttons {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.button-2, .button-3, .button-4 {
  margin-left: 0;
}

.button-1, .button-2, .button-3, .button-4 {
  width: 100%;
  max-width: 300px;
}

button {
  cursor: pointer;
  border-color: black;
  background-color: transparent;
  border-radius: 3rem;
  border: solid 0.01px;
  color: white;
  padding: 1rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button:hover {
  text-shadow: 0 0 20px rgba(255, 255, 255, 1);
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.2),
    0 0 20px rgba(255, 255, 255, 0);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
}

@media (min-width: 768px) {
  .home-buttons {
    flex-direction: row;
    justify-content: center;
  }

  .button-1, .button-2, .button-3, .button-4 {
    width: auto;
  }

  button {
    width: auto;
    padding: 1rem 2.5rem;
  }
}

@media (max-width: 559px) {
  .home-wrapper {
    width: 100vw;
  }
  .home-main {
    color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding: 20% 8%;
  }
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.1rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border-width: 10rem;
  }

  .button-2 .button-1 .button-3, .button-4 {
    font-size: 1rem;
    margin-left: 0;
  }

  .button-2 {
    margin-left: 0;
  }

  button {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    font-size: 10px;
  }
}

// @media only screen and (min-width: 560px) {
//   .button-4 {
//     display: none;
//   }
// }

.top-nav {
  background: #333;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.top-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.top-nav ul li {
  float: left;
}

.nav-button {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 3rem;
  background-color: #596985;
  margin: 0.5rem;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.nav-button:hover {
  background-color: #ddd;
  color: black;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.blue-section {
  background-color: rgb(0, 0, 0); /* Light blue */
  padding: 20px;
  background-image: none; /* Ensure no background image */
  
  /* Add these styles for heading colors */
  h1 {
    // margin-top: 20px; /* Adjust the top margin as needed */
    margin-bottom: 30px; /* Adjust the bottom margin as needed */
    text-align: center; /* Optional: Center-align the text if needed */
    color: rgb(214,117,50)
  }

  #hof {
    margin-top: 20px;
  }
}

/* Custom button styles to override Bootstrap */
button {
  cursor: pointer;
  border-color: black;
  background-color: transparent;
  border-radius: 3rem;
  border: solid 0.01px;
  color: white;
  padding: 1rem 2.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
}

.card:hover button{
  background-color: #fff;
  color: #000;
  font-weight: bold;
}

/* Added CSS for carousel controls */
.carousel-control-prev, .carousel-control-next {
  z-index: 2; /* Ensure controls are above the cards */
}

.carousel-indicators {
  display: none !important;
}


// Card CSS
@media (min-width: 576px) {
  .staff-card {
    width: calc(50% - 20px); /* Two cards per row on small screens */
  }
}

@media (min-width: 768px) {
  .staff-card {
    width: calc(33.33% - 20px); /* Three cards per row on medium screens */
  }
}

@media (min-width: 992px) {
  .staff-card {
    width: calc(25% - 20px); /* Four cards per row on large screens */
  }
}

.staff-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.staff-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  height: 400px;
  text-align: center;
  color: whitesmoke;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.staff-card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: filter 0.3s ease;
}

.staff-card:hover:before {
  filter: grayscale(100%) brightness(0.7); /* Grayscale the image and dim it */
}

.image-container {
  width: 100%;
  padding-top: 133.33%; /* This creates a 3:4 aspect ratio (1080:1440) */
  position: relative;
  overflow: hidden;
}

.picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-color: cyan;
}


.content {
  position: absolute;
  z-index: 1;
  padding: 20px;
  transition: all 0.3s ease;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

// .staff-card:hover .content {
//   justify-content: center;
// }

.staff-card .name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
  background-color: rgb(255,87,34);
  border-radius: 15px;
  color: whitesmoke
}

.bio {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 20px; /* Optional: Add padding for better readability */
}

.staff-card:hover .bio {
    opacity: 0.8;
    max-height: none; /* Remove max-height to show full bio */  
}

.staff-card:hover .picture {
  filter: grayscale(50%) brightness(70%);
  opacity: 1;
}


@media (min-width: 600px) {
  .staff-card {
    width: calc(50% - 20px);
  }
}

@media (min-width: 900px) {
  .staff-card {
    width: calc(33.33% - 20px);
  }
}

@media (min-width: 1200px) {
  .staff-card {
    width: calc(25% - 20px);
  }
}